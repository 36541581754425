import { defineComponent } from 'vue';
import { EclButton, EclButtonType } from '@/shared/ecl/src/components';
import router from '@/router';
import { LocaleManager, SEOManager, BreadcrumbManager } from '@/managers';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: '404',
  components: {
    EclButton
  },
  setup() {
    const { t } = useI18n();
    const headTitle: string = t('general.404.head_title');
    SEOManager.setTitles(headTitle);
    BreadcrumbManager.addSingle({ title: headTitle });

    return {
      performBackToHome: () => {
        router.push(LocaleManager.makeUrlByName('home'));
      },
      EclButtonType
    };
  }
});
