import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ecl-u-pb-xl ecl-u-pb-lg-3xl" }

export function render(_ctx, _cache) {
  const _component_ecl_h = _resolveComponent("ecl-h")
  const _component_ecl_button = _resolveComponent("ecl-button")
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_row = _resolveComponent("ecl-row")
  const _component_ecl_container = _resolveComponent("ecl-container")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_ecl_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_ecl_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_column, { col: "12" }, {
              default: _withCtx(() => [
                _createVNode("main", null, [
                  _createVNode(_component_ecl_h, {
                    level: "2",
                    id: "something-went-wrong",
                    innerHTML: _ctx.$t('general.404.title')
                  }, null, 8, ["innerHTML"]),
                  _createVNode("p", {
                    innerHTML: _ctx.$t('general.404.description')
                  }, null, 8, ["innerHTML"]),
                  _createVNode("ul", {
                    class: "ecl-unordered-list",
                    innerHTML: _ctx.$t('general.404.bullet_list')
                  }, null, 8, ["innerHTML"]),
                  _createVNode(_component_ecl_button, {
                    class: "ecl-u-mt-l",
                    text: _ctx.$t('general.404.cta'),
                    ariaLabel: _ctx.$t('general.404.cta'),
                    type: _ctx.EclButtonType.Secondary,
                    onClick: _withModifiers(_ctx.performBackToHome, ["prevent"])
                  }, null, 8, ["text", "ariaLabel", "type", "onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}